<template>
  <nav id="menu" class="navbar navbar-inverse navbar-fixed-top shadow-lvl-2">
    <div class="navbar-container" ref="navbar">
      <div class="navbar-container__left">
        <div class="navbar-logo" ref="logo">
          <a href="/">
            <img
              src="/proxy.php?className=login&action=get_logo&file_request=true"
              alt="T1"
            />
          </a>
        </div>
        <div class="navbar-menu">
          <ul class="main-menu">
            <li
              v-for="item in menu"
              ref="menu"
              :key="item.url || item.module_key"
              :class="[
                activeMenu.id === item.id ? 'active' : '',
                item.isHidden ? 'is-hidden' : '',
              ]"
            >
              <!-- <a
                :href="'/' + (item.url || item.module_key)"
                class="fw-500"
                @click.prevent="
                  $router.push('/' + (item.url || item.module_key))
                "
              >
                {{ item.name }}
              </a> -->
              <router-link
                class="fw-500"
                :to="{
                  name: 'changePage',
                  params: { page: item.id },
                }"
              >
                {{ item.name }}
              </router-link>
            </li>
            <!-- <li
              v-if="moreItem.length"
              ref="more"
              :class="[
                activeMoreItem ? 'active menu-children' : 'menu-children',
              ]"
            >
              <a href="" class="fw-500">Еще</a>
              <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M7 10.5L12 15.5L17 10.5L7 10.5Z"
                  :fill="[activeMoreItem ? '#21272C' : '#788694']"
                />
              </svg>
              <ul class="shadow-lvl-2">
                <li
                  v-for="item in moreItem"
                  :key="item.URL"
                  :class="[module === item.MODULE ? 'active more' : '']"
                >
                  <a :href="item.URL" class="fw-500">{{ item.LABEL }}</a>
                </li>
              </ul>
            </li> -->
          </ul>
        </div>
      </div>
    </div>
  </nav>
</template>
<script>
export default {
  data() {
    return {
      // data: {
      //   // menu: [
      //   //   {
      //   //     MODULE: 'anketa_sb',
      //   //     LABEL: 'Анкета СБ',
      //   //     URL: '/questionnaire/',
      //   //   },
      //   //   {
      //   //     MODULE: 'anketa_kadrov',
      //   //     LABEL: 'Анкета кадров',
      //   //     URL: '/questionnaire2/',
      //   //   },
      //   // ],
      //   // company_logo:
      //   //   'test/logo-mini.png?v=OPQ_UMb7e0tB4pzQmpWiUQ&logo_md5=5d30ee56ac8b4aa2719acfab35eac275',
      // },
      // moreItem: [],
      // activeMoreItem: false,
      // cumulativeWidth: 0,
      // menuWidth: 660,
    }
  },
  mounted() {
    // if (window.location.search)
    //   this.activePage = window.location.href.split('=')[1].split('&')[0]
    // this.setActiveMoreMenu()
  },
  computed: {
    menu() {
      return this.$store.state.page.menu
    },
    activeMenu() {
      return this.$store.state.page.activeMenu
    },
    // activePage() {
    //   return
    // }
  },
  // methods: {
  //   setActiveMoreMenu() {
  //     this.moreItem.map((item) => {
  //       if (item.MODULE === this.module) this.activeMoreItem = true
  //     })
  //   },
  // },
}
</script>
<style lang="scss" scoped>
.is-hidden {
  display: none !important;
}

.navbar {
  background-color: $white;
  padding: 0 16px;
  min-height: 56px;
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  z-index: 5000;
}

.navbar-fixed-top {
  box-sizing: border-box;
  height: 56px;
  min-height: 56px;
}

.navbar-container {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.navbar-container__left {
  display: flex;
  align-items: center;
  height: 100%;
}

.navbar-container a {
  font-family: Roboto;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: $black-100;
}

/* Logo */

.navbar-logo img {
  width: 48px;
  height: 40px;
  object-fit: contain;
}

/* Menu */

.navbar-menu {
  display: flex;
  margin-left: 60px;
  height: 100%;
}

.main-menu {
  height: 100%;
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  list-style: none;
  margin: 0;
  padding: 0;
}

.main-menu a {
  cursor: pointer;
}

.main-menu li a:hover {
  color: $black-200 !important;
}

.main-menu .active a {
  color: $black-200;
}

.main-menu > li {
  display: inline-flex;
  align-items: center;
}
.main-menu > li + li {
  margin-left: 40px;
}
.main-menu > li > a {
  position: relative;
  text-decoration: none;
}
.main-menu > li > a:hover {
  text-decoration: none;
}
.main-menu > li > a:after {
  width: 0;
  height: 4px;
  background-color: $blue-120;
  border-radius: 4px 4px 0px 0px;
  content: '';
  left: 0;
  bottom: -18px;
  position: absolute;
}

.main-menu > .menu-children > a:after {
  width: 0;
  height: 4px;
  background-color: $blue-120;
  border-radius: 4px 4px 0px 0px;
  content: '';
  left: 0;
  bottom: -16px;
  position: absolute;
}

.main-menu > .menu-children > ul > li > a {
  color: $black-100;
}

.main-menu > .menu-children > ul > .active a {
  position: relative;
  color: $black-200;
}

.main-menu > .menu-children > ul > .active a::after {
  width: 4px;
  height: 20px;
  background-color: $blue-120;
  border-radius: 0px 4px 4px 0;
  content: '';
  left: -18px;
  bottom: 0px;
  position: absolute;
}

.main-menu > li.active a:after {
  width: 100%;
}
.main-menu li {
  margin: 0;
  white-space: nowrap;
}
.main-menu li.menu-children {
  position: relative;
}
.main-menu li.menu-children[class='active'] > a,
svg {
  color: red;
}
.main-menu li li.menu-children:after {
  position: absolute;
  content: '\2039';
  color: $white;
  font-size: 20px;
  font-weight: bold;
  right: 10px;
  top: 12px;
  transform: rotate(180deg);
}
.main-menu li.menu-children:hover > ul {
  opacity: 1;
  visibility: visible;
  transform: translateY(0);
}
.main-menu ul {
  padding: 9px 17px;
  margin: 0;
  list-style: none;
  background-color: $white;
  position: absolute;
  z-index: 20;
  min-width: 145px;
  top: 100%;
  left: -2px;
  opacity: 0;
  visibility: hidden;
  transform: translateY(5px);
  transition: all 200ms cubic-bezier(0.43, 0.59, 0.16, 1.25);
  border: 1px solid $black-20;
  box-sizing: border-box;
  border-radius: 4px;
}
.main-menu ul li {
  display: block;
  line-height: 1.1;
  margin-bottom: 16px;
}
.main-menu ul li:last-child {
  margin-bottom: 0;
}
.main-menu ul li a {
  display: block;
  transition: all 0.3s;
  text-decoration: none;
}
.navbar .navbar-menu .main-menu ul li a:hover {
  color: $black-200 !important;
  background: $white;
  text-decoration: none;
}
.main-menu ul ul {
  top: 0;
  left: 100%;
}
</style>
