<template>
  <div v-if="localSteps.length" class="steps">
    <div v-for="(step, i) in visualSteps" :key="step.id || i" class="step">
      <div class="step__status">
        <span
          v-if="!step.id && !step.name && !step.index"
          :style="`background-color: ${'#DADFE4'};`"
          class="fz-16 fw-500"
        >
          <Icon iconName="ellipsis" />
        </span>
        <Icon
          v-else-if="step.index < activeStepIndex || activeStepIndex === null"
          iconName="success"
          iconColor="#3DB449"
        />
        <span
          v-else
          :style="`background-color: ${
            step.index == activeStepIndex ? '#0075DB' : '#DADFE4'
          };`"
          class="fz-16 fw-500"
        >
          {{ +step.index + 1 }}
        </span>
      </div>
      <div
        v-if="step.name && step.id"
        class="step__name fz-14"
        :class="{
          secondary: step.index != activeStepIndex,
          primary: step.index == activeStepIndex,
        }"
      >
        {{ step.name || '' }}
      </div>
    </div>
  </div>
</template>
<script>
import Icon from '@/components/elements/Icon'
export default {
  components: { Icon },
  data() {
    return {
      MAX_STEPS_COUNT: 6,
    }
  },
  computed: {
    localSteps() {
      return this.$store.state.page.steps.map((s, index) => ({
        ...s,
        index,
      }))
    },
    activeStepIndex() {
      return this.$store.state.page.activeStepIndex
    },
    visualSteps() {
      return this.localSteps.length > this.MAX_STEPS_COUNT
        ? this.activeStepIndex <= 3
          ? this.localSteps
              .slice(0, 4)
              .concat([{ id: '', name: '' }])
              .concat(this.localSteps.slice(-1))
          : this.activeStepIndex >= this.localSteps.length - 4 ||
            this.activeStepIndex === null
          ? [{ id: '', name: '' }].concat(this.localSteps.slice(-5))
          : [{ id: '', name: '' }]
              .concat(
                this.localSteps.slice(
                  this.activeStepIndex - 1,
                  this.activeStepIndex + 2
                )
              )
              .concat([{ id: '', name: '' }])
              .concat(this.localSteps.slice(-1))
        : this.localSteps
    },
  },
}
</script>
<style lang="scss">
.steps {
  max-width: 1200px;
  height: 124px;
  display: flex;
  align-items: flex-start;
  padding: 24px;
  background: $white;
  border-radius: 6px;
  position: relative;
  margin-bottom: 16px;
  overflow: hidden;
}

.step {
  display: flex;
  align-items: center;
  flex: 1;
  flex-direction: column;
  position: relative;
  cursor: default;

  &__name {
    margin-top: 12px;
    text-align: center;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
  }

  &__status {
    display: flex;
    justify-content: center;

    span {
      width: 24px;
      height: 24px;
      border-radius: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
      color: $white;
    }

    &:before,
    &:after {
      content: '';
      display: inline-block;
      position: absolute;
      height: 1px;
      width: calc(50% - 24px);
      background: $black-30;
      top: 11.5px;
    }

    &:after {
      margin-left: 12px;
      right: 0;
    }

    &:before {
      margin-right: 12px;
      left: 0;
    }
  }

  &:first-child .step__status:before,
  &:last-child .step__status:after {
    content: none;
  }
}
</style>
