<template>
  <div class="page-wrapper">
    <nav-menu></nav-menu>
    <Steps />
    <router-view :key="$route.path" />
  </div>
</template>
<script>
import NavMenu from '@/components/elements/NavMenu'
import Steps from '@/components/elements/Steps'

export default {
  components: { NavMenu, Steps },
  computed: {
    activeStepIndex() {
      return this.$store.state.page.activeStepIndex
    },
    steps() {
      return this.$store.state.page.steps.map((s, index) => ({
        ...s,
        index,
      }))
    },
  },
  mounted() {
    this.$router.push({
      name: 'questionnairePage',
      params: {
        questionnaire: this.$store.state.page.activeMenu.module_key,
        step: this.steps[this.activeStepIndex]?.id,
      },
    })
  },
}
</script>
<style lang="scss">
@import '@/assets/scss/form.scss';
</style>
<style lang="scss" scoped>
.title {
  font-size: 24px;
  line-height: 32px;
}

.page-wrapper {
  margin-top: 76px;
  margin-bottom: 32px;
}
</style>
